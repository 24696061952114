function prop(filter) {
    let headerclass = "subheader"
    //headerclass = "subheader3"
    let aufgabe5, loesung5, help5, explainer5 // temp! Vorest noch alle aufgaben, dann Auswahl
    let aufgabe6, loesung6, help6, explainer6
    let aufgabe7, loesung7, help7, explainer7
    //let aufgabe8, loesung8, help8, explainer8

    const preis = ((getRandomInt(8) + 2)/2); // [1 - 4]
    const anzahl = getRandomInt(4) + 2; // [2 - 6]
    const mult = getRandomInt(4) + 2; // [2 - 6]
    
    const speisen = ["Döner", "Pizza Salami", "Pizza vetegarisch", "Burger", "Cheeseburger", "Gemüseburger", "Veggie Burger", "Große Pommes", "California Roll", "Nigiri", "Hähnchen Wrap", "Gemüse-Wrap", "Thunfisch-Wrap", "Hähnchensalat", "Thunfisch-Salat", "Feta-Käse-Salat", "Frühlingsrollen", "Currywurst", "Falafel-Wrap"]
    const speise = speisen[Math.floor(Math.random()*speisen.length)];
    //const speise = speisen[j];

    const reisen = [
        {stadt: 'Berlin',       zusatz: '',                                         ziel: 'Leipzig',        km: 172, zeit: 297, ziel2: 'Nürnberg',      km2: 441},
        {stadt: 'Berlin',       zusatz: '',                                         ziel: 'Hannover',       km: 285, zeit: 199, ziel2: 'Köln',          km2: 316},
        {stadt: 'Bremen',       zusatz: '',                                         ziel: 'Münster',        km: 172, zeit: 127, ziel2: 'Köln',          km2: 316},
        {stadt: 'Dresden',      zusatz: ' ("Elbflorenz")',                          ziel: 'Leipzig',        km: 115, zeit: 83,  ziel2: 'Hannover',      km2: 367},
        {stadt: 'Düsseldorf',   zusatz: ', der Hauptstadt von Nordrhein-Westfalen,', ziel: 'Frankfurt',     km: 228, zeit: 153, ziel2: 'Heidelberg',    km2: 289},
        {stadt: 'Erfurt',       zusatz: ', der Hauptstadt von Thüringen,',          ziel: 'Bamberg',        km: 173, zeit: 117, ziel2: 'Nürnberg',      km2: 230},
        {stadt: 'Hamburg',      zusatz: '',                                         ziel: 'Hannover',       km: 159, zeit: 109, ziel2: 'Kassel',        km2: 310},
        {stadt: 'Hannover',     zusatz: ', der Hauptstadt von Niedersachsen,',      ziel: 'Magdeburg',      km: 148, zeit: 94,  ziel2: 'Halle (Saale)', km2: 199},
        {stadt: 'Kiel',         zusatz: ', der Hauptstadt von Schleswig-Holstein',  ziel: 'Lübeck',         km: 88,  zeit: 73,  ziel2: 'Schwerin',      km2: 151},
        {stadt: 'Magdeburg',    zusatz: ', der Hauptstadt von Sachsen-Anhalt',      ziel: 'Leipzig',        km: 125, zeit: 80,  ziel2: 'Dresden',       km2: 231},
        {stadt: 'Mainz',        zusatz: ', der Hauptstadt von Rheinland-Pfalz',     ziel: 'Koblenz',        km: 98,  zeit: 62,  ziel2: 'Bonn',          km2: 168},
        {stadt: 'München',      zusatz: ', der bayerischen Landeshauptstadt',       ziel: 'Ulm',            km: 152, zeit: 110, ziel2: 'Stuttgart',     km2: 233},
        {stadt: 'Potsdam',      zusatz: ', der Landeshauptstadt von Brandenburg',   ziel: 'Brandenburg an der Havel', km: 54, zeit: 43, ziel2: 'Magdeburg', km2: 127},
        {stadt: 'Saarbrücken',  zusatz: ', der Hauptstadt des Saarlands',            ziel: 'Kaiserslautern', km: 69,  zeit: 47,  ziel2: 'Mannheim',      km2: 35},
        {stadt: 'Schwerin',     zusatz: ', der Hauptstadt von Mecklenburg-Vorpommern', ziel: 'Wismar',      km: 32,  zeit: 32,  ziel2: 'Rostock',       km2: 92},
        /*
        {stadt: 'Stuttgart',    zusatz: ', der Hauptstadt von Baden-Württemberg',   ziel: '',               km: 0,   zeit: 0,   ziel2: '', km2: 0},
        
        {stadt: 'Wiesbaden',    zusatz: '',                                         ziel: '',           km: 0,   zeit: 0,   ziel2: '', km2: 0},
        {stadt: 'Dortmund',     zusatz: '',                                         ziel: 'Essen',      km: 37,  zeit: 31,  ziel2: 'Duisburg',   km2: 59},
        {stadt: 'Frankfurt',    zusatz: ' ("Mainhattan")',                          ziel: '',           km: 0,   zeit: 0,   ziel2: '', km2: 0},
        {stadt: '',             zusatz: '',                                         ziel: '',           km: 0,   zeit: 0,   ziel2: '', km2: 0},
        */
    ]
    let reisentab = reisen.map((item)=>[item.stadt, item.zusatz, item.ziel, item.km, item.zeit, item.ziel2, item.km2])

    const j = Math.floor(Math.random()*reisentab.length)
    const [stadt, zusatz, ziel, km, zeit, ziel2, km2] = reisentab[j]
    const zeit2 = zeit*km2/km

    const apersonen2 = 3 + getRandomInt(5)
    const apersonen1 = apersonen2 * (getRandomInt(3)+1)
    const astund1 = 2 + getRandomInt(3)
    const astund2 = astund1 * (getRandomInt(2)+1)
    const atage1 = getRandomInt(11) + 2
    const atage2 =  (apersonen1 * astund1 * atage1) / (apersonen2 * astund2)
    const atodo = [
        "Um eine Grube für einen riesigen Pool auszuheben",
        "Um den Grünstreifen auf der Autobahn umzugraben",
        "Um die Leitungen des Bürokomplexes zu verlegen",
        "Um neue Fliesen in sämtlichen Bädern des Hotels zu verlegen",
        "Horror im Finanzamt! Um sämtliche ESt-Bescheide von 1998 zu digitalisieren",
        "Um alle Schallplatten des insolventen Musikhauses zu digitalisieren"
    ]
    const k = Math.floor(Math.random()*atodo.length)

    const kuchen = {kpersonen: 5, butter: 200, zucker: 200, eier: 4, mehl: 400}
    let {kpersonen, butter, zucker, eier, mehl} = kuchen
    const nku1 = 1 + getRandomInt(6)
    let nku2 = [4, 6].includes(nku1) ? nku1/2 : nku1 * (2*(getRandomInt(3)+1))
    
    function zutatstring(num) {
        return num >= 1000 ? (num/1000).toFixed(1) + " kg" : num + " g"
    }
    //console.log(zutatstring(nku2*butter))
    
    function zeith(zeit) {
        return Math.floor(zeit / 60 )+':'+(String(Math.floor( zeit % 60 )).padStart('2',0))
    }

    const rechteck1 = [[3,2], [4,2], [5,2], [6,2], [4,3], [5,3], [6,3], [7,3], [8,3], [5,4], [6,4], [7,4]]
    let rlaenge, rbreite, rlaengeneu, rbreiteneu
    [rlaenge, rbreite] = rechteck1[Math.floor(Math.random() * rechteck1.length)]
    //console.log(laenge, breite)
    const rectprop = 1 + getRandomInt(3)
    if (Math.random() < 0.5) {
        rbreiteneu =  rlaenge * rectprop
        rlaengeneu = rlaenge * rbreiteneu / rbreite
        aufgabe5 = `Ein Rechteck ist ${rlaenge.toFixed(1)} cm lang und ${rbreite.toFixed(1)} cm breit. Gib die Länge eines ähnlichen Rechtecks mit der Breite ${rbreiteneu.toFixed(1)} cm an.`
        loesung5 = `Das ähnliche Rechteck hat die Länge ${rlaengeneu.toFixed(1)} cm`
        help5 = `Bei ähnlichen Rechtecken ist das Verhältnis von Länge zu Breite gleich:
        <br>Die Länge des gesuchten Rechtecks in cm sei a
        \\[\\frac{Länge}{Breite} = \\frac{${rlaenge.toFixed(1)}}{${rbreite.toFixed(1)}} = \\frac{a}{${rbreiteneu.toFixed(1)}} \\]
        `//!
        explainer5 = `(alle Maße haben die Einheit cm, deshalb lassen wir sie in der Rechnung weg und das Ergebnis ist dann cm)
        \\[\\frac{Länge}{Breite} = \\frac{${rlaenge.toFixed(1)}}{${rbreite.toFixed(1)}} = \\frac{a}{${rbreiteneu.toFixed(1)}} \\]
        Umstellen, je nach Methode: "die Gleichung mit ${rbreiteneu.toFixed(1)} multiplizieren" oder "die Breite ${rbreiteneu.toFixed(1)} auf die andere Seite in den Zähler bringen"
        \\[\\frac{${rlaenge.toFixed(1)}}{${rbreite.toFixed(1)}} \\cdot  ${rbreiteneu.toFixed(1)} = a \\]
        \\[a = ${rlaengeneu.toFixed(1)}\\]
        Beachte, dass im Lösungssatz die Einheit cm steht, während die Rechnung nur mit den Zahlenwerten gemacht wird. (Falsch wäre es, am Ende der Rechnung die cm einfach dranzukleben)
        `//!
    } else {
        rlaengeneu = Math.round( rlaenge / rectprop )
        if (rlaengeneu === rlaenge) rlaengeneu++
        rbreiteneu =  rbreite * rlaengeneu / rlaenge
        aufgabe5 = `Ein Rechteck ist ${rlaenge.toFixed(1)} cm lang und ${rbreite.toFixed(1)} cm breit. Gib die Breite eines ähnlichen Rechtecks mit der Länge ${rlaengeneu.toFixed(1)} cm an.`
        loesung5 = `Das ähnliche Rechteck hat die Breite ${rbreiteneu.toFixed(1)} cm`
        help5 = `Bei ähnlichen Rechtecken ist das Verhältnis von Länge zu Breite gleich:
        <br>Die Breite des gesuchten Rechtecks in cm sei b
        \\[\\frac{Länge}{Breite} = \\frac{${rlaenge.toFixed(1)}}{${rbreite.toFixed(1)}} = \\frac{${rlaengeneu.toFixed(1)}}{b} \\]
        `//!
        explainer5 = `(alle Maße haben die Einheit cm, deshalb lassen wir sie in der Rechnung weg und das Ergebnis ist dann cm)
        \\[\\frac{Länge}{Breite} = \\frac{${rlaenge.toFixed(1)}}{${rbreite.toFixed(1)}} = \\frac{${rlaengeneu.toFixed(1)}}{b} \\]
        Umstellen:
        \\[b = \\frac{${rlaengeneu.toFixed(1)}}{${rlaenge.toFixed(1)}} \\cdot  ${rbreite.toFixed(1)} \\]
        \\[b = ${rbreiteneu.toFixed(1)}\\]
        Beachte, dass im Lösungssatz die Einheit cm steht, während die Rechnung nur mit den Zahlenwerten gemacht wird. (Falsch wäre es, am Ende der Rechnung die cm einfach dranzukleben)
        `//!
    }

    if (Math.random() < 0.5) {
        const steigungrez = 2 + getRandomInt(5) // Steigungen 1/6 .... 1/2
        aufgabe6 = `Du möchtest die Gerade \\[y = \\frac{1}{${steigungrez}} \\cdot x \\] möglichst genau in ein x-y-Koordinatensystem einzeichnen. Welches Steigungsdreieck ist geeignet? Gib die Breite (x) und die Höhe (y) in cm an.`
        loesung6 = steigungrez === 2 || steigungrez === 3 ? `Z.B. Breite: ${steigungrez * 2} cm; Höhe = 2 cm` : `Z.B. Breite: ${steigungrez} cm; Höhe = 1 cm`
        help6 = `Die Steigung ist \\(\\frac{1}{${steigungrez}} \\). Ein Steigungsdreieck mit Breite 1 cm und Höhe \\(\\frac{1}{${steigungrez}} \\) cm ist aber nicht geeignet, wenn du genau zeichnen willst.
        <br>Wie wäre es, wenn das Steigungsdreick die Breite ${steigungrez === 2 || steigungrez === 3 ? steigungrez*2 : steigungrez} cm hätte? Was wäre dann die Höhe?
        `//!
        explainer6 = `Alle Steigungsdreiecke zu einer Geraden sind ähnlich! Oder: Nimm verschiedene Steigungsdreiecke zur selben Geraden und Höhe zu Breite stehen immer im selben Verhältnis. Dieses Verhältnis ist nichts anderes als die Steigung der Geraden. Beispiele für Steigungsdreiecke für die Steigung \\(\\frac{1}{${steigungrez}} \\):
        <br>Breite 2 cm; Höhe \\(${getlowestfraction(2/steigungrez, "injax")}\\) cm
        <br>Breite 3 cm; Höhe \\(${getlowestfraction(3/steigungrez, "injax")}\\) cm
        <br>Breite 4 cm; Höhe \\(${getlowestfraction(4/steigungrez, "injax")}\\) cm
        <br>Breite 5 cm; Höhe \\(${getlowestfraction(5/steigungrez, "injax")}\\) cm
        <br>Breite 6 cm; Höhe \\(${getlowestfraction(6/steigungrez, "injax")}\\) cm
        <br>Fürs Zeichnen praktisch sind die mit ganzen oder halben Zentimetern!
        `//
    } else {
        const steigungdec = (1 + getRandomInt(9)) / 10 // Steigungen 0,1 .... 0,9
        aufgabe6 = `Du möchtest die Gerade \\[y = ${steigungdec.toFixed(1)} \\cdot x \\] möglichst genau in ein x-y-Koordinatensystem einzeichnen. Welches Steigungsdreieck ist geeignet? Gib die Breite (x) und die Höhe (y) in cm an.`
        loesung6 = `Z.B. Breite = 5 cm; Höhe = ${steigungdec * 5} cm`
        help6 = `Die Steigung ist ${steigungdec.toFixed(1)}. Mit einem Steigungsdreieck von Breite 1 cm und Höhe ${steigungdec.toFixed(1)} cm ist es aber schwierig, die Gerade genau zu zeichnen! Wie wäre es, wenn eine Breite von von 5 cm oder 10 cm statt 1 cm nimmst? Was wäre dann die Höhe?`
        explainer6 = `Alle Steigungsdreiecke zu einer Geraden sind ähnlich! Oder: Nimm verschiedene Steigungsdreiecke zur selben Geraden und Höhe zu Breite stehen immer im selben Verhältnis. Dieses Verhältnis ist nichts anderes als die Steigung der Geraden. Beispiele für Steigungsdreiecke für die Steigung ${steigungdec.toFixed(1)}:
        <br>Breite 2 cm; Höhe ${(2*steigungdec).toFixed(1)} cm
        <br>Breite 3 cm; Höhe ${(3*steigungdec).toFixed(1)} cm
        <br>Breite 4 cm; Höhe ${(4*steigungdec).toFixed(1)} cm
        <br>Breite 5 cm; Höhe ${(5*steigungdec).toFixed(1)} cm
        <br>Breite 6 cm; Höhe ${(6*steigungdec).toFixed(1)} cm
        <br>Breite 10 cm; Höhe ${(10*steigungdec).toFixed(1)} cm
        <br>Fürs Zeichnen praktisch sind die mit ganzen oder halben Zentimetern! Das mit 10 cm Breite hat den Charme, dass du gar nicht rechnen musst: die Höhe ist einfach 10 Mal die Steigung.
        `//
    }

    // Nr 7 Konstante Geschwindigkeit
    let rand7 = Math.random()
    if (rand7 < .33) {
        const vmetersec = 5*(1 + getRandomInt(10)) // Geschwindigkeit 5 .... 50 m/s
        const sekunden = 1 + getRandomInt(20)
        let sekunden2 = Math.random() < 0.5 ? sekunden + 1 + getRandomInt(30) : sekunden - getRandomInt(sekunden)
        if (sekunden2 === sekunden) sekunden2++
        const sek  = sekunden===1  ? "Sekunde" : "Sekunden"
        const sek2 = sekunden2===1 ? "Sekunde" : "Sekunden"
        aufgabe7 = `Ein Fahrzeug hat in ${sekunden} ${sek} bei konstanter Geschwindigkeit ${vmetersec*sekunden} Meter zurückgelegt.
        <br>1) Welche Strecke s<sub>2</sub> legt es in ${sekunden2} ${sek2} zurück?
        <br>2) Wie groß ist seine Geschwindigkeit?`
        loesung7 = `1) Die Strecke s<sub>2</sub> nach t = ${sekunden2} s beträgt ${vmetersec*sekunden2} m.
        <br>2) Geschwindigkeit v = ${vmetersec} \\(\\frac{m}{s}\\)`
        help7 = `Bei konstanter Geschwindigkeit bleibt das Verhältnis von Strecke zu Zeit immer gleich:
        <br>\\(\\frac{Strecke}{Zeit} = \\frac{${vmetersec*sekunden} m}{${sekunden} s} = \\frac{s_2}{${sekunden2} s} \\)
        `//!
        explainer7 = `Die Gleichung
        \\[\\frac{${vmetersec*sekunden} m}{${sekunden} s} = \\frac{s_2}{${sekunden2} s} \\]
        kannst du nach s<sub>2</sub> auflösen!
        Das Verhältnis \\[\\frac{Strecke}{Zeit} = \\frac{${vmetersec*sekunden} m}{${sekunden} s} = ${vmetersec} \\frac{m}{s} = v\\]
        ist die Geschwindigkeit v! Die Gleichung oben kannst du also einfacher schreiben:
        \\[${vmetersec} \\frac{m}{s} = \\frac{s_2}{${sekunden2} s} \\]
        ... und nach s<sub>2</sub> auflösen:
        \\[${vmetersec} \\frac{m}{s} \\cdot ${sekunden2} s = s_2 \\]
        Wie du siehst, kürzen sich die Sekunden weg und s<sub>2</sub> bekommt die Einheit m, wie es sein muss.
        `//!
    } else if (rand7 < .66) {
        let viertelh = 1 + getRandomInt(10)
        if (viertelh === 4) viertelh = 8
        const std = viertelh / 4
        const zeithr = Math.floor(viertelh/4)
        const zeitmin = (viertelh % 4) * 15
        const vkmh = 48 + 4*(1 + getRandomInt(14)) // Geschwindigkeit 5 .... 50 m/s
        const strecke = vkmh * std
        aufgabe7 = `"Ich habe eine Durchschnittsgeschwindigkeit von ${vkmh} km/h und muss ${strecke} km fahren. Wie lange brauche ich?"
        `//!
        loesung7 = `Die Fahrzeit ist ${std} Stunden,  ${zeithr === 0 ? "also " : zeithr + "h und "} ${zeitmin} min` 
        help7 = `km/h heißt km pro Stunde. Also ${vkmh} km in 1 Std. ("1 h")
        <br>Hmm, wieviel Zeit wird dann gebraucht für ${strecke} km?
        <br>${vkmh > strecke ? "Weniger" : "Mehr"} als 1 Stunde, oder?
        <br>Es muss um soviel ${vkmh > strecke ? "weniger" : "mehr"} als 1 Stunde sein, wie ${strecke} km ${vkmh > strecke ? "weniger" : "mehr"} als ${vkmh} km sind!
        <br>Welcher Faktor macht aus dem Wert ${vkmh} den Wert ${strecke}?
        <br>Spoiler: es ist der Faktor ${strecke}/${vkmh} = ${(strecke/vkmh).toFixed(2)}
        `//!
        explainer7 = `<small><b>Weg 1</b> (an Proportionalität denken): Wieviel Mal ${vkmh > strecke ? "weniger" : "mehr"} als ${vkmh} km sind ${strecke} km? \\(\\frac{${strecke}}{${vkmh}} = ${std}!\\) Also muss die Zeit auch um den Faktor ${std} ${vkmh > strecke ? "kleiner" : "größer"} sein als 1 Stunde. Lösung ${std} Std.
        <br><br><b>Weg 2</b> (Dreisatz): \\(\\frac{${vkmh} km}{1 h} = \\frac{${strecke} km}{Zeit?}\\). Dies nach der Zeit auflösen: \\(Zeit = \\frac{1 h}{${vkmh} km} \\cdot ${strecke} km\\). Es kommen wieder ${std} h raus!
        <br><br><b>Weg 3</b> (Physik: konstante Geschwindigkeit)
        <br>\\(v = \\frac{s}{t}\\) bzw. \\(s = v \\cdot t\\) bzw. \\(t = \\frac{s}{v}\\)
        <br>(v: Geschwindigkeit in km/h, s: Strecke in km, t: Zeit in h). Die dritte Formel gibt
        \\[t = \\frac{${strecke}}{${vkmh}} = ${std}\\]
        Das Ergebnis der Rechnung, ohne Einheit, ist ${std}. Die Lösung der Aufgabe muss den Zahlenwert mit Einheit enthalten, also:
        <br>"Die Zeit ist ${std} Stunden"
        </small>`//!
    } else {
        let strecke7 = 85 + 5*getRandomInt(40) // km/h 85 bis 285
        let zeith7 = 2 + getRandomInt(4)    // Std
        let zeitv7 = 1 + getRandomInt(3)    // Viertelstd
        let std7 = zeith7 + zeitv7/4
        aufgabe7 = `Ein Auto legt eine Strecke von ${strecke7} km in ${zeith7} Stunden und ${15*zeitv7} Minuten zurück. Berechne seine mittlere Geschwindigkeit in km/h`
        loesung7 = `Die Geschwindigkeit beträgt ${Math.round(strecke7/std7)} km/h`
        help7 = `Die mittlere Geschwindigkeit  in km/h ist: Strecke in km geteilt durch Zeit in h.
        <br>Du brauchst also die Zeit in Stunden.
        <br>Tipp: ${15*zeitv7} Minuten sind ${15*zeitv7}/60 Stunden, also ${(zeitv7/4).toFixed(2)} Stunden
        `//!${15*zeitv7} 
        explainer7 = `Die <i>mittlere Geschwindigkeit</i> einer Bewegung ist eine Strecke geteilt durch die Zeit (die für die Strecke gebraucht wurde):
        <ul>
            <li>Die Strecke ist ${strecke7} km</li>
            <li>Die Zeit ist ${std7.toFixed(2)} h</li>
            <li>\\(\\frac{Strecke}{Zeit} = \\frac{${strecke7} km }{${std7.toFixed(2)} h} = ${Math.round(strecke7/std7)} \\frac{km}{h}  \\)</li>
        </ul>
        
        `//!
        
    }

    /*
    const spannung1 = 10 + 10*(getRandomInt(4))
    const spannung2 = 100 + 10*(getRandomInt(10))
    const widerstand = 20 + 10*(getRandomInt(5))
    aufgabe8 = ``
    loesung8 = ``
    help8 = ``
    explainer8 = ``
    */

    const aufgaben = [
        {
            nr:1,
            title: "Döner, Burger, Pizza, Falafel",
            description: "", 
            aufgabe: `${anzahl} ${speise} kosten ${(anzahl*preis).toFixed(2)} Euro, wieviel kosten ${anzahl*mult} ${speise}?`,
            loesung: `${(anzahl*mult*preis).toFixed(2)} Euro!`,
            help: `2 Tipps:
            <br>1. Rechne erst den Preis von 1 ${speise} aus!
            <br>2. Überlege, wieviel mal mehr die ${anzahl*mult} ${speise} sind, als die ${anzahl}! (Spoiler: es sind ${mult}mal so viel)`,
            explainer: `Hier die beiden Möglichkeiten:
            <br>1. Teile die ${(anzahl*preis).toFixed(2)} Euro durch ${anzahl}, dann hast du den Einzelpreis. Diesen kannst du mit ${anzahl*mult} multiplizieren, um das Ergebnis zu erhalten.
            <br><br>2. Profi-Tipp: ${anzahl*mult} ${speise} sind ${mult}-mal soviel wie ${anzahl}. Der Preis von ${anzahl*mult} ${speise} muss also auch ${mult}mal soviel wie ${(anzahl*preis).toFixed(2)} Euro sein.
            <br><br>
            Methode 1 geht immer, auch wenn die Zahlen nicht so passend sind. Wenn aber die Zahlen passen, dann ist Methode 2 cooler.`,
        },
        {
            nr:2,
            title: "Reisen in Deutschland", 
            description: "", 
            aufgabe: `Von ${stadt}${zusatz} nach ${ziel} sind es ${km} km und die Fahrzeit mit PKW beträgt ${zeith(zeit)} Std. Wie lange würde, bei gleicher mittlerer Geschwindigkeit, die Fahrt von Berlin ins ${km2} km entfernte ${ziel2} dauern?`,
            //loesung: `${zeith(Math.round(km2*zeit/km))} Std. ---- ${zeith(zeit2)}`,
            loesung: `${zeith(zeit2)} Stunden`,
            help: `Die Strecken verhalten sich wie die Zeiten, da die Geschwindigkeit immer gleich ist:
            \\[\\frac{${km}\\text{ km}}{${zeit}\\text{ min}} = \\frac{${km2}\\text{ km}}{\\text{gesuchte Zeit}}\\]
            (zum Rechnen müssen alle Zeiten in derselben Einheit, also hier in Minuten angegeben werden!)
            `,
            explainer: `Die Gleichung unter <kbd>&nbsp; ? &nbsp;</kbd>  wird umgestellt, also nach der gesuchten Zeit aufgelöst:
            \\[\\text{Gesuchte Zeit = } \\frac{${km2}\\text{ km}}{${km}\\text{ km}} \\cdot ${zeit}\\text{ min}\\]
            `//!
        },
        {
            nr:3,
            title: "Doppelter Dreisatz: Arbeitsleistung", 
            description: "",  
            aufgabe: `${atodo[k]}, arbeiten ${apersonen1} Personen ${atage1} Tage zusammen, ${astund1} Stunden pro Tag.
            Wie viele Tage bräuchte ein anderes Team mit ${apersonen2} Personen bei einer täglichen Arbeitszeit von ${astund2} Stunden?`,
            loesung: `${atage2} Tage`,
            help: `Die Arbeitsmenge ist gegeben durch
            <br><br>Personen mal Tage mal Stunden pro Tag
            <br> - für beide Teams.
            <br><br>Es sind also 6 Größen gegeben, eine davon ist unbekannt. Nach dieser musst du auflösen.`,
            explainer: `Arbeitsumfang der Teams 1 und 2 ist (p: Personen, t: Tage, s: Stunden pro Tag): 
            \\[p2 \\cdot t2 \\cdot s2 = p1 \\cdot t1 \\cdot s1\\]
            \\[t2 = \\frac{p1 \\cdot t1 \\cdot s1}{p2 \\cdot s2}\\]
            \\[t2 = \\frac{${apersonen1} \\cdot ${atage1} \\cdot ${astund1}}{${apersonen2} \\cdot ${astund2}}\\]
            `//
        },
        {
            nr:4,
            title: "Backe backe Kuchen", 
            description: "", 
            aufgabe: `Kuchenparty: ihr backt ${nku1} Rührkuchen für ${nku1*kpersonen} Personen und braucht dafür ${zutatstring(nku1*butter)} Butter, ${zutatstring(nku1*zucker)} Zucker, ${nku1*eier} Eier und ${zutatstring(nku1*mehl)} Mehl. Wieviel von allen Zutaten wären für ${nku2*kpersonen} Personen nötig?`,
            loesung: `${zutatstring(butter*nku2)} Butter, ${zutatstring(zucker*nku2)} Zucker, ${eier*nku2} Eier, ${zutatstring(mehl*nku2)} Mehl`,
            help: `Für alle Zutaten gilt
            \\[\\frac{\\text{Menge für ${nku2*kpersonen}}}{\\text{Menge für ${nku1*kpersonen}}}=\\frac{${nku2*kpersonen}}{${nku1*kpersonen}} = ${getlowestfraction(nku2/nku1, "injax")}\\]
            `,//!
            explainer: `\\[\\frac{\\text{Menge für ${nku2*kpersonen}}}{\\text{Menge für ${nku1*kpersonen}}}= ${getlowestfraction(nku2/nku1, "injax")}\\]
            Also z.B. \\[\\frac{\\text{Eier für ${nku2*kpersonen}}}{\\text{Eier für ${nku1*kpersonen}}}= ${getlowestfraction(nku2/nku1, "injax")}\\]
            \\[\\text{Eier für ${nku2*kpersonen}} = ${getlowestfraction(nku2/nku1, "injax")} \\cdot \\text{(Eier für }${nku1*kpersonen})\\]
            \\[\\text{Eier für ${nku2*kpersonen}} = ${getlowestfraction(nku2/nku1, "injax")} \\cdot ${nku1*eier} \\text{ Eier}\\]
            Du musst also alle Zutaten mit \\(${getlowestfraction(nku2/nku1, "injax")}\\) multiplizieren!
            `//!
        },
        {
            nr:5,
            title: "Ähnliche Rechtecke", 
            description: "", 
            aufgabe: aufgabe5,
            loesung: loesung5,
            help: help5,
            explainer: explainer5
        },
        {
            nr:6,
            title: "Das optimale Steigungsdreieck", 
            description: "", 
            aufgabe: aufgabe6,
            loesung: loesung6,
            help: help6,
            explainer: explainer6
        },
        {
            nr:7,
            title: "Konstante Geschwindigkeit m/s, km/h", 
            description: "", 
            aufgabe: aufgabe7,
            loesung: loesung7,
            help: help7,
            explainer: explainer7
        },
    ]

    function getlowestfraction(x0,format) {
        let eps = 1.0E-15;
        let h, h1, h2, k, k1, k2, a, x;
        //let format = "jax"
        x = x0;
        a = Math.floor(x);
        h1 = 1;
        k1 = 0;
        h = a;
        k = 1;
        while (x-a > eps*k*k) {
            x = 1/(x-a);
            a = Math.floor(x);
            h2 = h1; h1 = h;
            k2 = k1; k1 = k;
            h = h2 + a*h1;
            k = k2 + a*k1;
        }
        // return h + "/" + k;
       
           // (format === "jax") ? `\\[\\frac{${h}}{${k}}\\]` : h + "/" + k
        if (format === "jax"){
            return k === 1 ? `\\[{${h}}\\]` : `\\[\\frac{${h}}{${k}}\\]`
        } else if (format === "injax") {
            return k === 1 ? `{${h}}` : `\\frac{${h}}{${k}}`
        } else if (format === "text") {
            return `${h} geteilt durch ${k}`;
        } else {
            return h + "/" + k;
        }
    }
    
    //const i = 3 //test
    const i = ( typeof filter == 'number' ? filter : Math.floor(Math.random() * aufgaben.length))
    

    const [aufgabe_,loesung_,help_,explainer_] = [aufgaben[i].aufgabe,aufgaben[i].loesung,aufgaben[i].help,aufgaben[i].explainer]

    let menu = "Das Sub-Menü in kommt Kürze"
    menu = aufgaben.filter(item => item.nr !== 0); // die nr:0 elemente nur in der Gesamtauswahl
    menu = menu.map(({ nr, title, description }) => ({
        nr,
        title,
        description,
      }));
      //console.log(menu)

      return [aufgabe_,loesung_,help_,explainer_,headerclass,menu]
}

export default prop;

function getRandomInt(n) { 
    return Math.floor(Math.random() * n);
}